/**
 * Created by Riven on 2018/4/20.
 */

import React from "react";
import { Table, Button } from "antd";
import { decode } from "js-base64"
import { selectTopic } from "../state/iot"

const buildColumn = onDelete => [
  {
    title: "序号",
    dataIndex: "key"
  },
  {
    title: "时间",
    dataIndex: "date",
    sorter: (a, b) => a.dateRaw - b.dateRaw
  },
  {
    title: "Value",
    dataIndex: "value",
    render: (text) => {
      if (text.startsWith('data:image/png;base64')){
        return <img src={text} />
      } else {
        return text;
      }
    },
    ellipsis: true
  },
  {
    title: "ClienID",
    dataIndex: "clientid"
  },
  {
    title: "操作",
    key: "action",
    render: (_, record) => (
      <span>
        <Button
          type="danger"
          shape="circle"
          icon="delete"
          size="small"
          onClick={() => {
            onDelete(record._id);
          }}
        />
      </span>
    )
  }
];

const buildData = (data, type)=> {
  const dataTB = [];
  let count = 1;
  for (let d of data) {
    if(!d){
      const t = {
        _id: '',
        key: count,
        value: '',
        dateRaw: '',
        date: '',
        clientid: ''
      };
      dataTB.push(t);
      count += 1;
      continue
    }
    let v;
    if (type === 'number'||type === 'txt'){
       if(/[\u4e00-\u9fa5]/.test(d.data)){
        v = d.data
       }else{
        v = decode(d.data)
       }
    } else {
      v = d.data
    }
    const date = new Date(`${d.date}+00:00`); // server save in utc-0
    const t = {
      _id: d.id,
      key: count,
      value: v,
      dateRaw: d.date,
      date: date.toLocaleString(),
      clientid: d.client_id
    };
    dataTB.push(t);
    count += 1;
  }
  return dataTB;
};
const DataTables = ({ data, type, onDelete, topic, dispatch, page, ...props }) => {
  return(<Table columns={buildColumn(onDelete)} dataSource={buildData(data, type)} size="middle" onChange={(e)=>{
    dispatch(selectTopic(topic,e.current,10))
  }} pagination={{current:page}} />);
}

export default DataTables;
