/**
 * Created by Riven on 2018/4/20.
 */
import React from "react";
import ReactEcharts from "echarts-for-react";
import echarts from "echarts";

const buildOption = data => {
  // const dataMap = {}
  // data.forEach(e => {
  //   const date = new Date(e[1]+"+00:00")
  //   const key = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`
  //   dataMap[key] = dataMap[key]
  //                         ? dataMap[key] + 1
  //                         : 1
  // })
  // const xdata = []
  // const ydata = []
  // Object.keys(dataMap).forEach((key) => {
  //   xdata.push(key)
  //   ydata.push(dataMap[key])
  // })
  
  // 对于非number类型的 ydata会自动留空，设计中就是这样，不需要做额外处理 ~
  const xdata = data.map(d => {
    const date = new Date(d.date+"+00:00");
    return date.toLocaleString();
  });
  const ydata = data.map(d => {
    try {
      let ret = atob(d.data);
      if (isNaN(Number(ret))) {
        ret = 0;
      }
      // todo: other case?
      return ret;
    } catch (e) {
      return null;
    }
  });
  const option = {
    title: {
      text: "",
      left: "50%",
      show: false,
      textAlign: "center"
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        lineStyle: {
          color: "#ddd"
        }
      },
      backgroundColor: "rgba(255,255,255,1)",
      padding: [5, 10],
      textStyle: {
        color: "#7588E4"
      },
      extraCssText: "box-shadow: 0 0 5px rgba(0,0,0,0.3)"
    },
    legend: {
      right: 20,
      orient: "vertical"
    },
    xAxis: {
      type: "category",
      data: xdata,
      boundaryGap: false,
      splitLine: {
        show: true,
        interval: "auto",
        lineStyle: {
          color: ["#D4DFF5"]
        }
      },
      axisTick: {
        show: false
      },
      axisLine: {
        lineStyle: {
          color: "#609ee9"
        }
      },
      axisLabel: {
        margin: 10,
        textStyle: {
          fontSize: 10
        }
      }
    },
    yAxis: {
      type: "value",
      splitLine: {
        lineStyle: {
          color: ["#D4DFF5"]
        }
      },
      axisTick: {
        show: false
      },
      axisLine: {
        lineStyle: {
          color: "#609ee9"
        }
      },
      axisLabel: {
        margin: 0,
        textStyle: {
          fontSize: 8
        }
      }
    },
    series: [
      {
        name: "Value",
        type: "line",
        smooth: true,
        showSymbol: false,
        symbol: "circle",
        symbolSize: 6,
        data: ydata,
        areaStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(
              0,
              0,
              0,
              1,
              [
                {
                  offset: 0,
                  color: "rgba(216, 244, 247,1)"
                },
                {
                  offset: 1,
                  color: "rgba(216, 244, 247,1)"
                }
              ],
              false
            )
          }
        },
        itemStyle: {
          normal: {
            color: "#58c8da"
          }
        },
        lineStyle: {
          normal: {
            width: 3
          }
        }
      }
    ]
  };
  return option;
};

const EchartsViews = ({ data, ...props }) => {
  return <ReactEcharts
    option={buildOption(data)}
    style={{ height: "350px", width: "100%" }}
    className={"react_for_echarts"}
  />
};

export default EchartsViews;
