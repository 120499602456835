import { getReq, postReq } from "../utils/network";
import iot from "./iot";

const UPDATE_PROFILE = "UPDATE_PROFILE";

// const loginHost = "http://localhost:8991/";
// const loginHost = "http://api.kittenbot.cn:8991/";

const initialState = {
  userprofile: null
};

const reducer = function(state, action) {
  if (typeof state === "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case UPDATE_PROFILE:
      console.log(Object.assign({}, state, {
        userprofile: action.profile
      }));
      return Object.assign({}, state, {
        userprofile: action.profile
      });
    default:
      return state;
  }
};

  reducer.updateUserProfile = function(profile) {
  return function(dispatch, getState) {
    dispatch({ type: UPDATE_PROFILE, profile });
    dispatch(iot.listTopics());
  };
};

reducer.getUserProfile = function() {
  return function(dispatch, getState) {
    getReq(`${window.iot.loginHost}/user/current`)
      .then(json => {
        console.log(json);
        dispatch({ type: UPDATE_PROFILE, profile: json });
        dispatch(iot.listTopics());
      })
      .catch(err => {
        // todo: global unlogin notify
      });
  };
};

reducer.userLogOut = function() {
  return function(dispatch, getState) {
    localStorage.removeItem('token')
    dispatch({ type: UPDATE_PROFILE, profile: null });
  };
};

export { reducer as default };
