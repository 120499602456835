import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
// import logo from './logo.svg';
import './App.css';
import "./IotDashboard.less";
import { postReq } from "./utils/network";

import mqtt from "mqtt/dist/mqtt.js";
import iot, { mqttAddData, selectTopic } from "./state/iot";
import user from "./state/user";
import AddTopicForm from "./components/AddTopicForm/index.jsx";
import  { updateTopicState } from "./state/topic";
import EditTopicForm from "./components/EditTopicForm.jsx";

import {
    Layout,
    message,
    Input,
    Modal,
} from "antd";
import LoginForm from './components/Login';
import { useDidMount } from './utils';
import { AlertContent } from './components/AlertContent';
import { SiderBar } from './components/Sider';
import { VIEW_ACTION } from './state/view';
import { Topic } from './components/Topic';
import { updateMqttState } from './state/mqtt';

window.iot = {
    // http://localhost:8991
    loginHost: 'https://api.kittenbot.cn/v2',
    apiHost: "https://api.kittenbot.cn/v2"
}

// window.iot = {
//     // http://localhost:8991
//     loginHost: 'http://localhost:3000/v2',
//     apiHost: "http://localhost:3000/v2"
// }

const confirm = Modal.confirm;

const makeid = () => {
    var text = '';
    var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  
    for (var i = 0; i < 5; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
  
    return text;
};
const Apps = () => {
    const { userprofile } = useSelector(_ => _.user)
    const {
        topicList,
        topicSelected,
        topicData,
        page
    } = useSelector(_ => _.iot)
    const {
        showAddTopic,
        showEditTopic,
        publicTopicModal,
        qos1,
    } = useSelector(_ => _.view)
    const {
        editingTopic,
        publicTopicName,
    } = useSelector(_ => _.topic)
    const {
        mqttHostTopic,
        mqttClient,
        mqttHost
    } = useSelector(_ => _.mqtt)
    const addtopicform = useRef(null)
    const edittopicform = useRef(null)

    const [state, setState] = useState({
        showAddTopic: false,
        showEditTopic: false,
        authname: "",
        authpass: "",
        retoken: null,
        qos1: true,
        mobilemode: false,
    })

    useEffect(() => {
        updateState({
            authname: topicSelected ? topicSelected.authname : '',
            authpass: topicSelected ? topicSelected.authpass : '',
        })
    }, [topicSelected])

    useDidMount(() => {
        if (window.location.protocol === 'https:'){
          setState(prevState => ({
            ...prevState,
          }))
        }
        dispatch(user.getUserProfile())
    })

    const dispatch = useDispatch()

    const updateView = (payload) => {
        dispatch({
            type: VIEW_ACTION.UPDATE_STATE,
            payload
        })
    }

    const updateState = (payload) => {
        setState(prevState => ({ ...prevState, ...payload }))
    }

    const genetareTopic = useCallback(() => {
        dispatch(updateTopicState({ topicList: topicList }))
    }, [topicList])

    useEffect(() => {
        genetareTopic()
    }, [topicList])

    const onCloseEditTopic = () => {
        updateView({
            showEditTopic: false
        });
    }

    const onAddTopic = () => {
        console.log(addtopicform);
        const form = addtopicform.current.props.form;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            postReq(`${window.iot.apiHost}/iot/topics`, {...values, type: 'number'})
            .then(ret => {
                dispatch(iot.listTopics());
                message.success('创建成功')
            })
            .catch(ret => {
                const r = JSON.parse(ret)
                console.warn(r);
                message.error(r.msg);
            });
            updateView({ showAddTopic: false })
        });
    }

    const onEditTopic = () => {
        const form = edittopicform.current.props.form;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            let authname = values.authname;
            let authpass = values.authpass;
            const topicName = values.topic;
            if (!authname || !authpass){
                authname = undefined;
                authpass = undefined;
            }
            // TODO: add type config
            dispatch(iot.updateTopicAuth(topicName, authname, authpass, 'number'));
            updateView({ showEditTopic: false });
        });
    }
    const handleAddPublicTopic = () => {
        const topicName = publicTopicName;
        updateView({publicTopicModal: false})
        dispatch(selectTopic(topicName))
    }

    const onCloseAddTopic = () => {
        updateView({
            showAddTopic: false
        })
    }

    const updateMqtt = (payload) => dispatch(updateMqttState(payload))

    const onConnectMqttHost = () => {
        const { topic } = topicSelected
        if (window.mqttClient) {
            window.mqttClient.end();
            delete window.mqttClient
        }
        if (topic === mqttHostTopic) return;
 
        const option = {
            clientId: "web-debug-" + makeid(),
            reconnectPeriod: 0,
        }
        if (state.authname && state.authname.length > 0) {
            option.username = state.authname;
        }
        if (state.authpass && state.authpass.length > 0) {
            option.password = state.authpass;
        }

        const nextMqttHostTopic = topicSelected && topicSelected.topic;
        const client = mqtt.connect(
            mqttHost,
            option
        );
        client.on("message", (topic, msg) => {
            const _msg = new TextDecoder("utf-8").decode(msg);
            if(topic===topicSelected.topic){
                dispatch(mqttAddData(topic,_msg,option.clientId))
            }
            message.warning(`MQTT: ${_msg}`);
        });
        client.on("end", () => {
            updateMqtt({
                mqttHostTopic: null,
            })
            delete window.mqttClient
            dispatch(iot.setDebuggingTopic(null))
        });
        client.on("error", e => {
            console.log("mqtt err", e);
        });
        client.on("connect", connect => {
            console.log(state);
            console.log('connect', client, mqttHostTopic );
            client.retryCnt = 0;
            dispatch(iot.setDebuggingTopic(nextMqttHostTopic))
            updateMqtt({
                mqttHostTopic: nextMqttHostTopic,
            })
            window.mqttClient = client;
            client.subscribe(nextMqttHostTopic);
        });
        client.on("reconnect", () => {
            console.log("mqtt reconnecting");
            client.retryCnt++;
            if (client.retryCnt > 5) client.end();
        });
        client.on("close",()=>{
            updateMqtt({
                mqttHostTopic: null,
            })
        })
    }

    const showIcon = publicTopicName === mqttHostTopic

    const updateUserProfile = (n) => {
        dispatch(user.updateUserProfile(n));
    }
    return (
        <div className="App">
            {
                userprofile
                ? (
                    <>
                        <div style={{background: "#facc15",display: "flex",justifyContent: "center", alignItems: 'center',padding: '5px',gap:'10px'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle-alert "><circle cx="12" cy="12" r="10"></circle><line x1="12" x2="12" y1="8" y2="12"></line><line x1="12" x2="12.01" y1="16" y2="16"></line></svg>
                            <span>平台由于技术调整，消息记录功能已下线。后续将在新的平台开放相关功能，且本平台不会关闭请放心使用！</span>
                        </div>
                        <Layout className="content-wrap">
                            <SiderBar
                                showIcon={showIcon}
                            />
                            <Layout>
                                {
                                    topicSelected
                                    ? (
                                        <Topic
                                            type={topicSelected.type}
                                            authname={state.authname}
                                            authpass={state.authpass}
                                            topicData={topicData}
                                            handleInputChange={updateState}
                                            onConnectMqttHost={onConnectMqttHost}
                                            page={page}
                                            state={state}
                                            topicList={topicList}
                                        />
                                    )
                                    : <AlertContent text="请先选择或创建一个Topic" />
                                }
                            </Layout>
                            <AddTopicForm
                                wrappedComponentRef={addtopicform}
                                visible={showAddTopic}
                                onCancel={onCloseAddTopic}
                                onCreate={onAddTopic}
                            />
                            <EditTopicForm
                                wrappedComponentRef={edittopicform}
                                visible={showEditTopic}
                                onCancel={onCloseEditTopic}
                                onEdit={onEditTopic}
                                topic={editingTopic}
                            />
                            <Modal
                                title="查看公开话题"
                                visible={publicTopicModal}
                                onOk={handleAddPublicTopic}
                                onCancel={()=> updateView({publicTopicModal: false})}
                            >
                                <Input
                                    onChange={e => updateState({ publicTopicName: e.target.value })}
                                    placeholder="/kittenhome/temp"
                                />
                            </Modal>
                        </Layout>
                    </>
                )
                : (
                    <LoginForm
                        updateUserProfile={updateUserProfile}
                    />
                )
            }
        </div>
    )
}





export default Apps
