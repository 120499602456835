const defaultState = {
  publicTopicName: '',
  topicList: [],
  editingTopic: {}
}

export const TOPIC_ACTION = {
  UPDATE_STATE: Symbol('UPDATE_STATE')
}

const reducer = (prevState = defaultState, action) => {
  const state = JSON.parse(JSON.stringify(prevState))
  const {
    type,
    payload,
    ...otherPayload
  } = action
  switch (action.type) {
    case TOPIC_ACTION.UPDATE_STATE:
      return {
        ...state,
        ...otherPayload,
        ...payload,
      }
    default:
      return state
  }
}

export const updateTopicState = (payload) => {
  return {
    type: TOPIC_ACTION.UPDATE_STATE,
    payload,
  }
}

export default reducer