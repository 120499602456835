import React, { useEffect, useState } from 'react'
import {
    Layout,
    Row,
    Col,
    Input,
    Divider,
    Button,
    Icon,
    Checkbox,
    Card, Spin,
    Tabs,
    message,
    Modal,
    Select
} from 'antd'

import iotReducer, { chartUpdate, selectTopic, updateTopicData } from '../../state/iot'
import { postReq, delReq } from "../../utils/network"
import Datatables from "../Datatables";
import Datacharts from "../Datacharts";
import { useSelector, useDispatch } from 'react-redux';
import './style.less'
import { updateViewState } from '../../state/view';

const { TabPane } = Tabs
const { Content } = Layout
const { confirm } = Modal
const { Option } = Select

export const Topic = ({
    authname,
    authpass,
    type,
    topicData,
    handleInputChange,
    onConnectMqttHost,
    state,
    topicList
}) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const {
        topicSelected = {},
        updating,
        chatData,
        page,
        showAmount
    } = useSelector(_ => _.iot)
    const {
        qos1,
        selectedTab
    } = useSelector(_ => _.view)
    const {
        mqttHostTopic,
    } = useSelector(_ => _.mqtt)
    const { topic } = topicSelected
    const onDeleteData = () => {
        confirm({
            title: "是否确认删除所有数据？",
            content: "将会清除该所有数据，并且不可恢复",
            onOk() {
                const values = { topic }
                delReq(`${window.iot.apiHost}/iot/`).then(ret => {
                    message.success("数据删除成功");
                    dispatch(updateTopicData());
                }).catch(err => {
                    message.error(`Topic数据删除失败 ${err}`);
                });
            },
            onCancel() { }
        });
    }

    const onDeleteDataByID = (_id) => {
        console.log({_id});
        delReq(`${window.iot.apiHost}/iot/data/${_id}`).then(ret => {
            dispatch(iotReducer.localDelData(_id));
            message.success("数据删除成功");
        }).catch(err => {
            message.error(`Topic数据删除失败 ${err}`)
        })
    }
    const onDownloadData = () => {
        setLoading(true)
        const topic = topicSelected.topic.replaceAll('/','$')
        fetch(`${window.iot.apiHost}/iot/topic/${topic}`,{
            method:'GET',
            headers:{
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(ret => ret.json()).then(ret => {
            const data = ret.data.data;
            let csv = "data:text/csv;charset=utf-8,"
            csv += 'date,data,client\r\n'
            data.forEach(n => {
                const v = atob(n.data);
                const date = new Date(n.date+"+00:00")
                csv += `${date},${v},${n.client_id}\r\n`
            })
            const encodedUri = encodeURI(csv);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", `${topicSelected.topic}.csv`);
            document.body.appendChild(link); // Required for FF
            link.click()
            document.body.removeChild(link);
            message.success('下载成功')
        }).catch(error => {
            message.error(`Topic数据下载失败 ${error}`)
        }).finally(()=>{
            setLoading(false)
        })
    }

    const onToggleQos = () => {
        dispatch(updateViewState({
            qos1: !qos1
        }))
    }

    const onSendTopicMsg = (msg) => {
        const topicInfo = topicList.filter(item=>item.topic===topic)[0]
        if(topicInfo.username&&!state.authname||topicInfo.username&&topicInfo.username!==state.authname){
            let pass = state.authname&&topicInfo.username===state.authname&&topicInfo.password===state.authpass
            if(!pass) return message.warning('向该话题推送消息需要正确用户名、密码')
        }
        if (window.mqttClient) {
            const qos = Number(qos1);
            window.mqttClient.publish(mqttHostTopic, msg.toString(), { qos });
        }
    }

    const onChangeDataTab = (tabKey) => {
        dispatch(updateViewState({ selectedTab: tabKey }))
    }

    const handleReloadData = () => {
        dispatch(selectTopic())
    }

    const isConnected = !mqttHostTopic
    return (
        <Content className="topic-wrapper">
            <h1 style={{paddingTop: "19px"}}>当前话题：{topicSelected.topic}</h1>
            <Divider>调试</Divider>
            <div className="topic-body">
                <Row>
                    <Col span={8} offset={1}>
                        <Input
                            onChange={e => handleInputChange({
                                authname: e.target.value
                            })}
                            placeholder="话题登录名"
                            value={authname}
                            disabled={!isConnected}
                        />
                    </Col>
                    <Col span={8} offset={1}>
                        <Input
                            onChange={e => handleInputChange({
                                authpass: e.target.value
                            })}
                            placeholder="话题登录密码"
                            value={authpass}
                            disabled={!isConnected}
                        />
                    </Col>
                    <Col span={3} offset={1}>
                        {mqttHostTopic === topic
                            ? (
                                <Button
                                    type="danger"
                                    onClick={onConnectMqttHost}
                                >
                                    <Icon type="link" />断开连接
                                </Button>
                            )
                            : (
                                <Button onClick={onConnectMqttHost}>
                                    <Icon type="link" />连接
                                </Button>
                            )}
                    </Col>
                </Row>
            </div>
            <div style={{ marginTop: 10 }}>
                <Row>
                    <Col span={10} offset={1}>
                        <Input.Search
                            disabled={isConnected}
                            placeholder="测试发送消息"
                            onSearch={onSendTopicMsg}
                            enterButton="发送"
                        />
                    </Col>
                    <Col span={4}>
                        <Checkbox
                            checked={qos1}
                            onChange={onToggleQos}
                        >数据保存</Checkbox>
                    </Col>
                </Row>
            </div>
            <Divider />
            <div>
                {updating ? (
                    <Spin size="large" />
                ) : (
                        <Card title="数据显示">
                            <Tabs
                                defaultActiveKey={selectedTab}
                                onChange={onChangeDataTab}
                                tabBarExtraContent={
                                    <div id='syncBox'>
                                        <div id='sync' onClick={handleReloadData}>
                                            <Icon type="sync" />
                                        </div>
                                    </div>
                                }
                            >
                                <TabPane tab="消息" key="1">
                                    <Datatables data={topicData} type={type} onDelete={onDeleteDataByID} topic={topicSelected.topic} dispatch={dispatch} page={page} />
                                </TabPane>
                                <TabPane tab="图表" key="2">
                                    <div style={{display:'flex',columnGap:'5px',alignItems:'center'}}>
                                        <div>展示数据量:</div>
                                        <Select value={showAmount} onChange={e=>{
                                            dispatch(chartUpdate(topicSelected.topic,e))
                                        }}>
                                            <Option value={10}>10</Option>
                                            <Option value={20}>20</Option>
                                            <Option value={50}>50</Option>
                                            <Option value={100}>100</Option>
                                            <Option value={200}>200</Option>
                                        </Select>
                                    </div>
                                    <Datacharts data={chatData} />
                                </TabPane>
                            </Tabs>
                        </Card>
                    )}
            </div>
            <Button
                type="info"
                onClick={onDownloadData}
                style={{ margin: 10 }}
                loading={loading}
            >
                下载数据
            </Button>
            <Divider type="vertical" />
            {/* <Button
                type="danger"
                onClick={onDeleteData}
                style={{ margin: 10 }}
            >
                删除所有数据
            </Button> */}
        </Content>
    )
}