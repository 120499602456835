const defaultState = {
  showAddTopic: false,
  showEditTopic: false,
  publicTopicModal: false,
  qos1: true,
  selectedTab: "1",
}

export const VIEW_ACTION = {
  UPDATE_STATE: Symbol('UPDATE_STATE')
}

const reducer = (prevState = defaultState, action) => {
  const state = JSON.parse(JSON.stringify(prevState))
  switch (action.type) {
    case VIEW_ACTION.UPDATE_STATE:
      // console.log('payload', action.payload);
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export const updateViewState = (payload) => ({ type: VIEW_ACTION.UPDATE_STATE, payload })

export default reducer