const defaultState = {
  mqttHostTopic: null,
  mqttHost: `wss://${window.location.host}/mqtt`,
}

export const MQTT_ACTION = {
  UPDATE_STATE: Symbol('UPDATE_STATE')
}

// riven, mqttClient 原型是eventemitter， 通用的object类型更新会丢失eventmitter的prototype而变成一个object
const reducer = (prevState = defaultState, action) => {
  const state = JSON.parse(JSON.stringify(prevState))
  switch (action.type) {
    case MQTT_ACTION.UPDATE_STATE:
      // console.log('payload', action.payload);
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

const updateState = (payload) => ({
  type: MQTT_ACTION.UPDATE_STATE,
  payload
})

export default reducer

export {
  updateState as updateMqttState,
}