const buildForm = function(values) {
  let formBody = [];
  for (var property in values) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(values[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");

  return formBody;
};

const getReq = function(url, token) {
  if (!token) token = localStorage.getItem('token')
  const request = {
    method: "GET",
    // credentials: "include",
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
  return new Promise((resolve, reject) => {
    fetch(url, request)
      .then(res => {
        if (res.ok) {
          try {
            res.json().then(json => {
              if (json.status){
                json.status == 200 ? resolve(json.data) : reject(json.msg)
              } else {
                resolve(json);
              }
            });
          } catch (err) {
            reject(err)
          }
        } else {
          res.text().then(txt => {
            reject(txt);
          });
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

const getDownload = function(url, values, token) {
  if (!token) token = localStorage.getItem('token')
  const formBody = buildForm(values);
  var request = {
    method: "GET",
    // credentials: "include",
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
  url = url + "?" + formBody;
  return new Promise((resolve, reject) => {
    fetch(url, request)
      .then(response => {
        console.log(response);
        return response.blob();
      })
      .then(blob => {
        resolve(blob);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const postReq = function(url, values, token) {
  if (!token) token = localStorage.getItem('token')
  var request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: token ? `Bearer ${token}` : '',
    },
    // credentials: "include",
    body: JSON.stringify({...values})
  };

  return new Promise((resolve, reject) => {
    fetch(url, request)
      .then(res => {
        if (res.ok) {
          res.json().then(json => {
            if (json.status){
              (json.status == 200 || json.status == 201) ? resolve(json.data) : reject(json.msg)
            } else {
              resolve(json);
            }
          });
        } else {
          res.text().then(txt => {
            reject(txt);
          });
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

const putReq = function(url, values, token) {
  if (!token) token = localStorage.getItem('token')
  var request = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: token ? `Bearer ${token}` : '',
    },
    body: JSON.stringify({...values})
  };
  return new Promise((resolve, reject) => {
    fetch(url, request)
      .then(res => {
        if (res.ok) {
          res.json().then(json => {
            if (json.status){
              (json.status == 200 || json.status == 201)  ? resolve(json.data) : reject(json.msg)
            } else {
              resolve(json);
            }
          });
        } else {
          res.text().then(txt => {
            reject(txt);
          });
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

const delReq = function(url, values, token) {
  if (!token) token = localStorage.getItem('token')
  var request = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: token ? `Bearer ${token}` : '',
    },
    body: JSON.stringify({...values})
  };

  return new Promise((resolve, reject) => {
    fetch(url, request)
      .then(res => {
        if (res.ok) {
          res.json().then(json => {
            if (json.status){
              json.status == 200 ? resolve(json.data) : reject(json.msg)
            } else {
              resolve(json);
            }
          });
        } else {
          res.text().then(txt => {
            reject(txt);
          });
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export { getReq, getDownload, postReq, delReq, putReq };
