/**
 * Created by Riven on 2018/4/21.
 */
import { getReq, putReq } from "../utils/network";
import { message } from "antd/lib/index";
import { VIEW_ACTION } from "./view";

const initialState = {
  updating: false,
  topicList: [],
  topicSelected: null,
  topicDebugging: null,
  topicData: [],
  chatData: [],
  page:1,
  showAmount:10,
  version: "2.6.6",
};

//缓存
const cache = {}

// const apihost = "";

const UPDATE_TOPICLIST = "UPDATE_TOPICLIST";
const UPDATE_TOPICINFO = "UPDATE_TOPICINFO";
const UPDATE_TOPICSELECTED = "UPDATE_TOPICSELECTED";
const UPDATE_TOPICDATA = "UPDATE_TOPICDATA";
const UPDATE_CHATDATA = "UPDATE_CHATDATA";
const UPDATE_TOPICDEBUG = "UPDATE_TOPICDEBUG";
const UPDATING = "UPDATING";

const reducer = function(state, action) {
  if (typeof state === "undefined") {
    state = initialState;
  }
  switch (action.type) {
    case UPDATING:
      return Object.assign({}, state, {
        updating: true,
        // topicSelected: {}
      });
    case UPDATE_TOPICLIST:
      return Object.assign({}, state, {
        topicList: action.topics,
        updating: false
      });
    case UPDATE_TOPICSELECTED:
      return Object.assign({}, state, {
        topicSelected: action.topic,
        topicData: action.data,
        chatData:action.data.slice(0,10),
        showAmount:10,
        page: action.page||1,
        updating: false
      });
    case UPDATE_TOPICDATA:
      return Object.assign({}, state, {
        topicData: action.data,
        chatData:action.data.slice(0,state.showAmount),
        page: action.page||1,
        updating: false
      });
    case UPDATE_CHATDATA:
      return Object.assign({}, state, {
        chatData: action.chatData,
        showAmount: action.showAmount,
        updating: false
      })
    case UPDATE_TOPICINFO:
      return Object.assign({}, state, {
        topicSelected: action.topic,
        updating: false
      });
    case UPDATE_TOPICDEBUG:
      return Object.assign({}, state, {
        topicDebugging: action.topic,
        updating: false
      });
    default:
      return state;
  }
};

export const updateTopicData = function() {
  return function(dispatch, getState) {
    const topicName = getState().iot.topicSelected.topic;
    dispatch({ type: UPDATING });
    getReq(`${window.iot.apiHost}/iot/topic/${topicName.replace(/\//g, '$')}`)
      .then( data => {
          dispatch({ type: UPDATE_TOPICDATA, data: data.data,page:1 });
      })
      .catch(err => {
        console.log("err get topic", err);
      });
  };
};

reducer.updateTopicAuth = function(topicName, authname, authpass, type) {
  return function(dispatch, getState) {
    putReq(`${window.iot.apiHost}/iot/topic/${topicName.replace(/\//g, '$')}`, {
      username: authname, password: authpass, type
    }).then(topic => {
        message.success("Topic 鉴权更新成功");
        dispatch({ type: UPDATE_TOPICINFO, topic: topic });
        dispatch(reducer.listTopics(false));
      })
      .catch(err => {
        console.log("update fail", err);
      });
  };
};

export const mqttAddData = function(topicName,msg,clientId){
  const id = 'xxx'
  const client_id = clientId
  const date = new Date().toISOString().replace('Z', '')
  let data = ''
  try{
    data = btoa(msg)
  }catch(err){
    data = msg
  }
  cache[topicName]['data'].unshift({
    client_id,data,date,id
  })
  cache[topicName]['pages'] = [1]
  return function(dispatch,_getState){
    dispatch({ type: UPDATING });
    dispatch({type:UPDATE_TOPICDATA,data:cache[topicName]['data'], page:1})
  }
}

export const chartUpdate = function(topicName,max){
  return function(dispatch,_getState){
    const hasDataNum = cache[topicName]['data'].findIndex(data=>!data)
    if(hasDataNum>=max){
      dispatch({ type: UPDATE_CHATDATA, chatData:cache[topicName]['data'].slice(0,max),showAmount:max})
    }else{
      getReq(`${window.iot.apiHost}/iot/topic/${topicName.replace(/\//g, '$')}/${max}`).then(ret=>{
        cache[topicName]['data'].splice(0,max,...ret.data)
        dispatch({ type: UPDATE_CHATDATA, chatData:cache[topicName]['data'].slice(0,max),showAmount:max})
      })
    }
  }
}

export const selectTopic = function(topicName,page=1,pageSize=10) {
  return function(dispatch, getState) {
    if(!topicName){topicName = getState().iot.topicSelected.topic;}
    let cached = false
    dispatch({ type: UPDATING });
    if(cache[topicName]&&cache[topicName]['pages'].includes(page)){
      cached = true
      dispatch({ type: UPDATE_TOPICSELECTED, topic: cache[topicName]['topic'], data: cache[topicName]['data'],page })
    }
    getReq(`${window.iot.apiHost}/iot/topic/${topicName.replace(/\//g, '$')}/${page}/${pageSize}`)
      .then(ret => {
        const topicData = ret.data
        const length = ret.allDataLength
        //如已有缓存，总数据长度未改变，直接跳过
        if(cached&&cache[topicName]&&cache[topicName]['data'].length===length) return
        //如已有缓存，总数据长度发生改变，则需重新缓存
        if(cache[topicName]&&cache[topicName]['data'].length!==length) cache[topicName] = null

        const tlist = getState().iot.topicList;
        const topic = tlist.find(t => t.topic === topicName);

        if (!topic) {
          getReq(`${window.iot.apiHost}/iot/topicowner?topic=${topicName}`)
            .then(obj => {
              const publicTopic = {
                topic: topicName,
                username: obj.username
              };
              //将数据存入缓存
              if(!cache[topicName]){
                cache[topicName]={}
                cache[topicName]['topic'] = topic
                cache[topicName]['pages'] = []
                cache[topicName]['data'] = new Array(length).fill()
              }
              cache[topicName]['pages'].push(page)
              cache[topicName]['data'].splice(10*(page-1),10,...topicData)
              if(page===1){
                dispatch({ type: UPDATE_TOPICSELECTED, topic: publicTopic, data:cache[topicName]['data'], page });
              }else{
                dispatch({type:UPDATE_TOPICDATA,data:cache[topicName]['data'], page})
              }
            })
            .catch(err => {
              console.log("err get topic", err);
            });
        } else {
          if (topic.authname === "undefined") {
            topic.authname = null;
          }
          if (topic.authpass === "undefined") {
            topic.authpass = null;
          }
          //将数据存入缓存
          if(!cache[topicName]){
            cache[topicName]={}
            cache[topicName]['topic'] = topic
            cache[topicName]['pages'] = []
            cache[topicName]['data'] = new Array(length).fill()
          }
          cache[topicName]['pages'].push(page)
          cache[topicName]['data'].splice(10*(page-1),10,...topicData)
          if(page===1){
            dispatch({ type: UPDATE_TOPICSELECTED, topic: topic, data:cache[topicName]['data'], page });
          }else{
            dispatch({type:UPDATE_TOPICDATA,data:cache[topicName]['data'], page})
          }
        }
      })
      .catch(err => {
        console.log("err get topic", err);
      });
  };
};

reducer.setDebuggingTopic = function(topicName) {
    return {type: UPDATE_TOPICDEBUG, topic: topicName}
}

reducer.listTopics = function(autoSel = true) {
  return function(dispatch, getState) {
    dispatch({ type: UPDATING });
    getReq(`${window.iot.apiHost}/iot/topics`)
      .then(topics => {
        dispatch({ type: UPDATE_TOPICLIST, topics });
        if (autoSel && topics.length) {
          const topicName = topics[0].topic;
          dispatch(selectTopic(topicName));
        }
      })
      .catch(err => {
        // todo: global unlogin notify
      });
  };
};

reducer.localDelData = function(id) {
  return function(dispatch, getState) {
    const topicData = getState().iot.topicData;
    const topicName = getState().iot.topicSelected.topic
    const newData = topicData.reduce((result, a) => {
      if (a&&a.id !== id) result.push(a);
      return result;
    }, []);
    cache[topicName]={}
    selectTopic(topicName)
  };
};

export { 
  reducer as default 
};
