import React, { useState, useMemo } from 'react'
import {
  Alert,
  Input,
  Form,
  message,
  Icon,
  Button
} from 'antd'
import { postReq } from '../../utils/network';
import './style.less'

const { Item: FormItem } = Form

/**
 *
 * @param {*} props
 */
const Login = props => {
  const { getFieldDecorator } = props.form;

  function handleSubmit(e) {
      e.preventDefault();
      props.form.validateFields((err, values) => {
      if (!err) {
          postReq(`${window.iot.loginHost}/auth/login`, values)
          .then(data => {
            localStorage.setItem('token', data.token)
            props.updateUserProfile(data);
          })
          .catch(err => {
              message.error("登录失败，请检查用户名和密码");
          });
      }
      });
  }

  const PwdPrefix = useMemo(() => <Icon type="lock" style={{ fontSize: 13 }} />, [])

  const FormExtra = useMemo(() => <span>帐号体系升级了，请使用喵家<a href={`https://zone.kittenbot.cn/user/register?redirect=${encodeURI(window.location)}`}>Zone</a>的帐号进行登录</span>, [])

  return (
    <div className="login">
        <Alert description="请先登录再使用IOT功能" type="warning" showIcon />
        <div className="login-form">
        <div className="login-logo">
            <span>登录</span>
        </div>
        <Form
          onSubmit={handleSubmit}
          style={{ maxWidth: "300px" }}
        >
            <FormItem>
            {getFieldDecorator("email", {
                rules: [{
                  required: true,
                  message: "请输入注册邮箱!"
                }]
            })(
                <Input
                prefix={<Icon type="user" style={{ fontSize: 13 }} />}
                placeholder="请使用Zone账号密码登录"
                />
            )}
            </FormItem>
            <FormItem>
            {getFieldDecorator("password", {
                rules: [{
                  required: true,
                  message: "请输入密码!"
                }]
            })(
                <Input
                  prefix={PwdPrefix}
                  type="password"
                />
            )}
            </FormItem>

            <FormItem extra={FormExtra} >
              <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  style={{ width: "100%" }}
              >
                  登录
              </Button>
            </FormItem>
        </Form>
        </div>
    </div>
  );
};

export const LoginForm = Form.create()(Login);

export default LoginForm
