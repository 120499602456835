import React from 'react'
import { Layout, Alert } from 'antd'


import './style.less'

const { Content } = Layout

/**
 * @typedef {{type?: 'success' | 'info' | 'warning' | 'error', text: String}} AlertProps
 * @param {AlertProps} props
 */
export const AlertContent = ({ text, type = "warning" }) => (
  <Content className="alert-content">
    <Alert
      description={text}
      type={type}
      showIcon
    />
  </Content>
)