import React, { useCallback, useState } from 'react'
import {
  Layout,
  Divider,
  Menu,
  Button,
  Icon,
  List,
  Modal,
  message
} from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import './style.less'
import user from "../../state/user"
import iot, { selectTopic } from "../../state/iot"
import { updateTopicState } from "../../state/topic"

import { VIEW_ACTION } from '../../state/view'
import { postReq, delReq } from '../../utils/network'

const { Sider } = Layout
const { SubMenu } = Menu
const { Item: ListItem } = List
const { confirm } = Modal

export const SiderBar = () => {
  const iotState = useSelector(state => state.iot)
  const topicState = useSelector(state => state.topic)
  const dispatch = useDispatch()
  const [openKeys, setMenuOpenKeys] = useState(['sub1'])

  const {
    publicTopicName,
    topicList
  } = topicState
  const { 
    version,
    topicDebugging
  } = iotState

  const updateView = useCallback((payload) => {
    dispatch({
      type: VIEW_ACTION.UPDATE_STATE,
      payload
    })
  }, [])


  const showEditTopicFn = (topic) => {
    if (topic.authname === "undefined") {
        topic.authname = null;
    }
    if (topic.authpass === "undefined") {
        topic.authpass = null;
    }
    console.log(topic, 'edint');
    updateView({
        showEditTopic: true,
    })
    dispatch(updateTopicState({
      editingTopic: topic || {},
    }))
}

const onDeleteTopic = (topic) => {
  const topicName = topic.topic;
  confirm({
      title: "是否确认删除Topic？",
      content: "话题删除将会清除所有数据，并且不可恢复",
      onOk() {
        delReq(`${window.iot.apiHost}/iot/topic/${topicName.replace(/\//g, '$')}`).then(ret => {
          dispatch(iot.listTopics());
        })
        .catch(err => {
            message.error(`Topic删除失败 ${err}`);
        });
      },
      onCancel() {}
  });
}

  const onLogOut = () => {
    dispatch(user.userLogOut());
  }

  const [isMobileMode, setMobileMode] = useState(false)
  const onSelectTopic = (topic) => {
    if (
        topic.key !== "addtopic" &&
        topic.key !== "iotconfig" &&
        topic.key !== "viewtopicTxt" &&
        topic.key !== "viewtopicBtn"
    ){
        dispatch(selectTopic(topic.key));
    }
  }

  const handleTopicSelected = (topic) => {
    dispatch(selectTopic(topic))
  }

  const handleSubMenuClick = () => {
    console.log(openKeys);
    setMenuOpenKeys((prev) => prev.indexOf('sub1') === -1 ? ['sub1'] : [])
  }

  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      onBreakpoint={broken => {
          // console.log("on break", broken);
      }}
      onCollapse={(collapsed) => {
          // console.log(collapsed, type);
          setMobileMode(collapsed)
      }}
      className="sider"
    >
        <div className="logo">
          <h2>IoT 控制面板 {version}</h2>
        </div>
        <Divider />
          <Button onClick={() => updateView({ showAddTopic: true })}>
            <Icon type="plus" />
            添加话题
          </Button>
          <Menu
            mode="inline"
            defaultSelectedKeys="1"
            openKeys={openKeys}
            onClick={onSelectTopic}
          >
            <SubMenu
                onTitleClick={handleSubMenuClick}
                key="sub1"
                title={
                  <span>
                      <Icon type="mail" />
                      <span>Topic 话题</span>
                  </span>
                }
            >
                {
                  topicList.map(t => {
                    return (
                      <Menu.Item key={t.topic} className="menutopic">
                        <div>
                              <span
                                onClick={() => handleTopicSelected(t.topic)}
                              >
                                {t.topic}
                              </span>
                            <Icon
                                className="menutopicicon"
                                type="edit"
                                onClick={()=>{
                                    showEditTopicFn(t);
                                }}
                            />
                            <Icon
                                className="menutopicicon"
                                type="delete"
                                onClick={() => {
                                  onDeleteTopic(t);
                                }}
                            />
                            {
                              t.topic === topicDebugging
                                ? <Icon className="menutopicicon" type="link" style={{color: 'green'}}/>
                                : null
                            }
                          </div>
                      </Menu.Item>
                    )
                  })
                }
            </SubMenu>
            <Divider />
            <Menu.Item key="viewtopicBtn">
                <Button onClick={() => updateView({publicTopicModal: true})}>
                    <Icon type="eye" />
                    查看公开话题
                </Button>
            </Menu.Item>
            {
              publicTopicName
                ? <Menu.Item key="viewtopicTxt">
                    <ListItem
                      key={publicTopicName}
                      className="menutopic"
                    >
                        <ListItem.Meta
                            title={
                              <a
                                href="/#"
                                onClick={() => dispatch(iot.selectTopic(publicTopicName))} >
                                {publicTopicName}
                              </a>
                            }
                        />
                        { 
                          publicTopicName === topicDebugging
                          ? <Icon className="menutopicicon" type="link" style={{color: 'green'}}/>
                          : null
                        }
                    </ListItem>
                </Menu.Item>
                : null
            }
            <Divider />
            <Menu.Item key="logout">
                <Button type="danger" onClick={onLogOut}>
                    <Icon type="logout" />
                    登出
                </Button>
            </Menu.Item>
        </Menu>
      </Sider>
  )
}