/**
 * Created by Riven on 2018/4/21.
 */
import React from "react";
import { Modal, Form, Input } from "antd";
const FormItem = Form.Item;

const AddTopicForm = Form.create()(
  class extends React.Component {
    render() {
      const { visible, onCancel, onCreate, form } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Modal
          visible={visible}
          title="新建 Topic"
          okText="新建"
          onCancel={onCancel}
          onOk={onCreate}
        >
          <Form layout="vertical" style={{ maxWidth: 300, margin: "auto" }}>
            <FormItem label="Topic 路径" help="topic的路径，全服务器唯一，先到先得">
              {getFieldDecorator("topic", {
                rules: [{ required: true, message: "请输入 Topic 路径" }]
              })(<Input placeholder="/user/topic" />)}
            </FormItem>
            <FormItem
              label="Topic 订阅用户名"
              help="设备登录的用户名，如果为空则所有人都可以向该话题发数据"
            >
              {getFieldDecorator("authname")(<Input type="textarea" />)}
            </FormItem>
            <FormItem label="Topic 订阅密码" help="设备登录用的秘钥">
              {getFieldDecorator("authpass")(<Input type="textarea" />)}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);

export default AddTopicForm;
