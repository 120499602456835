import thunk from "redux-thunk";
import { createStore, combineReducers, applyMiddleware } from "redux"

import iot from "./iot"
import user from "./user"
import topic from './topic'
import view from './view'
import mqtt from './mqtt'

const allReduce = combineReducers({
    user,
    iot,
    topic,
    view,
    mqtt
});

const middleware = [thunk];
const store = () => createStore(allReduce, applyMiddleware(...middleware));

export default store;
